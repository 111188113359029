<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="logo"></div>
    <div class="contactBox">
      <div class="webIcon" @click="toWeb"></div>
      <div class="telegram" @click="toTg"></div>
      <div class="potato" @click="toPotato"></div>
    </div>
    <div class="figure"></div>
    <div class="logoText"></div>
    <div class="downloadBox">
      <div class="topBox">
        <img class="qrcodeBox" :src="qrImg" alt="" />
        <div class="btnBox">
          <div class="iosBtn"></div>
          <div class="androidBtn"></div>
          <div class="ucTip"></div>
        </div>
      </div>
      <div class="installSuggestion"></div>
    </div>
    <div class="bottomText"></div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  methods: {
    toWeb() {
      window.open("https://t.me/ShangWuhXiao2Ge");
    },
    toTg() {
      window.open("https://t.me/mrhaose");
    },
    toPotato() {
      window.open("https://ptd6.app/mrhaose");
    },
  },
};
</script>

<style lang="scss" scoped>
.pcm-wrapper {
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/bg.webp") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    position: fixed;
    left: 100px;
    top: 61px;
    z-index: 9;
    height: 102px;
    width: 275px;
    background: url("./../../../assets/images/pc/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .contactBox {
    position: fixed;
    right: 80px;
    top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .webIcon {
      height: 60px;
      width: 60px;
      background: url("./../../../assets/images/pc/webIcon.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 40px;
      cursor: pointer;
    }
    .telegram {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/telegram.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 40px;
      cursor: pointer;
    }
    .potato {
      height: 68px;
      width: 68px;
      background: url("./../../../assets/images/pc/potato.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .figure {
    height: 900px;
    width: 729px;
    background: url("./../../../assets/images/pc/figure.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 207px;
    bottom: 70px;
  }
  .logoText {
    height: 403px;
    width: 839px;
    background: url("./../../../assets/images/pc/logoText.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: 207px;
    top: 194px;
  }
  .bottomText {
    height: 228px;
    width: 100%;
    background: url("./../../../assets/images/pc/bottomText.webp") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
  .downloadBox {
    position: absolute;
    right: 376px;
    bottom: 168px;
    .topBox {
      display: flex;
      .qrcodeBox {
        height: 210px;
        width: 210px;
        background-color: white;
        border-radius: 12px;
        overflow: hidden;
      }
      .btnBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
        .iosBtn {
          height: 68px;
          width: 324px;
          background: url("./../../../assets/images/pc/iosBtn.png") no-repeat;
          background-size: 100% 100%;
        }
        .androidBtn {
          height: 68px;
          width: 324px;
          background: url("./../../../assets/images/pc/androidBtn.png")
            no-repeat;
          background-size: 100% 100%;
          margin: 20px 0;
        }
        .ucTip {
          height: 20px;
          width: 250px;
          background: url("./../../../assets/images/pc/ucTip.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
    .installSuggestion {
      height: 44px;
      width: 630px;
      background: url("./../../../assets/images/pc/installSuggestion.png")
        no-repeat;
      background-size: 100% 100%;
      margin-top: 40px;
    }
  }
}
</style>
